import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { saveAs } from "file-saver";
import "font-awesome/css/font-awesome.min.css";
import "./App.css";

const App = () => {
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [assignedPerson, setAssignedPerson] = useState("");
  const [comments, setComments] = useState("");
  const [status, setStatus] = useState("Pending");
  const [assignedPeople, setAssignedPeople] = useState([]);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showDatePickerModal, setShowDatePickerModal] = useState(false);
  const [personName, setPersonName] = useState("");
  const [personEmail, setPersonEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [showPersonModal, setShowPersonModal] = useState(false);
  const [editingTask, setEditingTask] = useState(null);
  const [priority, setPriority] = useState("Medium");
  const [filterStatus, setFilterStatus] = useState("All");
  const [filterPriority, setFilterPriority] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const savedTasks = localStorage.getItem('tasks');
    const savedPeople = localStorage.getItem('assignedPeople');
    if (savedTasks) setTasks(JSON.parse(savedTasks));
    if (savedPeople) setAssignedPeople(JSON.parse(savedPeople));
  }, []);

  useEffect(() => {
    localStorage.setItem('tasks', JSON.stringify(tasks));
    localStorage.setItem('assignedPeople', JSON.stringify(assignedPeople));
  }, [tasks, assignedPeople]);

  const handleAddOrUpdateTask = () => {
    if (!assignedPeople.length) {
      setErrorMessage("Please add at least one assigned person.");
      return;
    }
    if (!selectedDate) {
      setErrorMessage("Please select a deadline date.");
      return;
    }
    if (newTask.trim()) {
      const taskData = {
        id: editingTask ? editingTask.id : uuidv4(),
        name: newTask,
        dueDate: selectedDate,
        assignedTo: assignedPerson || "N/A",
        comments,
        status,
        priority,
      };
      
      if (editingTask) {
        setTasks(tasks.map(task => task.id === editingTask.id ? taskData : task));
      } else {
        setTasks([...tasks, taskData]);
      }
      
      resetTaskForm();
      setShowTaskModal(false);
      setErrorMessage("");
      setEditingTask(null);
    }
  };

  const resetTaskForm = () => {
    setNewTask("");
    setSelectedDate(null);
    setAssignedPerson("");
    setComments("");
    setStatus("Pending");
    setPriority("Medium");
  };

  const handleAddPerson = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(personEmail)) {
      setEmailErrorMessage("Please enter a valid email address.");
      return;
    }
    setEmailErrorMessage("");
    if (personName && personEmail) {
      setAssignedPeople([...assignedPeople, { id: uuidv4(), name: personName, email: personEmail }]);
      setPersonName("");
      setPersonEmail("");
      setShowPersonModal(false);
    }
  };

  const exportTasksToCSV = () => {
    const csvContent = `data:text/csv;charset=utf-8,Task Name,Due Date,Assigned To,Comments,Status,Priority\n${tasks
      .map((task) => `${task.name},${task.dueDate ? task.dueDate.toLocaleDateString() : "N/A"},${task.assignedTo},${task.comments},${task.status},${task.priority}`)
      .join("\n")}`;
    const blob = new Blob([decodeURIComponent(encodeURI(csvContent))], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "tasks.csv");
  };

  const handleRemovePerson = (id) => {
    setAssignedPeople(assignedPeople.filter((person) => person.id !== id));
  };

  const handleEditTask = (task) => {
    setEditingTask(task);
    setNewTask(task.name);
    setSelectedDate(task.dueDate);
    setAssignedPerson(task.assignedTo);
    setComments(task.comments);
    setStatus(task.status);
    setPriority(task.priority);
    setShowTaskModal(true);
  };

  const handleDeleteTask = (taskId) => {
    setTasks(tasks.filter(task => task.id !== taskId));
  };

  const handleChangeTaskStatus = (taskId, newStatus) => {
    setTasks(tasks.map(task => 
      task.id === taskId ? { ...task, status: newStatus } : task
    ));
  };

  const filteredTasks = tasks.filter(task => {
    return (filterStatus === "All" || task.status === filterStatus) &&
           (filterPriority === "All" || task.priority === filterPriority) &&
           (task.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            task.assignedTo.toLowerCase().includes(searchTerm.toLowerCase()));
  });
// ... (continued from Part 1)

return (
  <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
    <h1 className="text-3xl font-bold mb-8">Task Manager</h1>

    {showTaskModal && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg relative">
          <button onClick={() => {setShowTaskModal(false); setEditingTask(null);}} className="absolute top-2 right-2 text-red-500 text-2xl">
            <i className="fa fa-times"></i>
          </button>
          <h3 className="text-lg font-bold mb-4">{editingTask ? "Edit Task" : "Create Task"}</h3>
          {errorMessage && <div className="text-red-500 mb-4">{errorMessage}</div>}
          <div className="flex items-center mb-4">
            <input
              type="text"
              className="border p-2 w-full mr-2"
              placeholder="Task Name"
              value={newTask}
              onChange={(e) => setNewTask(e.target.value)}
            />
            <button className="border p-2" onClick={() => setShowDatePickerModal(true)}>
              <i className="fa fa-calendar"></i>
            </button>
          </div>
          <select
            className="border p-2 w-full mb-4"
            value={assignedPerson}
            onChange={(e) => {
              const selected = assignedPeople.find(person => person.name === e.target.value);
              if (selected) {
                setAssignedPerson(selected.name);
              }
            }}
          >
            <option value="">Assign To</option>
            {assignedPeople.map((person) => (
              <option key={person.id} value={person.name}>
                {person.name}
              </option>
            ))}
          </select>
          <textarea
            className="border p-2 w-full mb-4"
            placeholder="Comments"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          ></textarea>
          <select
            className="border p-2 w-full mb-4"
            value={priority}
            onChange={(e) => setPriority(e.target.value)}
          >
            <option value="Low">Low</option>
            <option value="Medium">Medium</option>
            <option value="High">High</option>
          </select>
          <div className="flex justify-between items-center">
            <button onClick={handleAddOrUpdateTask} className="bg-blue-500 text-white p-3 rounded w-full">
              {editingTask ? "Update Task" : "Add Task"}
            </button>
          </div>
        </div>
      </div>
    )}

    {showDatePickerModal && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white rounded-lg shadow-lg w-full max-w-lg p-4">
          <h3 className="text-lg font-bold mb-4">Select Due Date</h3>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => {
              setSelectedDate(date);
              setShowDatePickerModal(false);
              if (errorMessage === "Please select a deadline date.") {
                setErrorMessage("");
              }
            }}
            inline
            className="w-full"
          />
          <button
            onClick={() => setShowDatePickerModal(false)}
            className="bg-blue-500 text-white p-2 rounded mt-4 w-full"
          >
            Close
          </button>
        </div>
      </div>
    )}

    {showPersonModal && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg relative">
          <button onClick={() => setShowPersonModal(false)} className="absolute top-2 right-2 text-red-500 text-2xl">
            <i className="fa fa-times"></i>
          </button>
          <h3 className="text-lg font-bold mb-4">Add Assigned Person</h3>
          {emailErrorMessage && <div className="text-red-500 mb-4">{emailErrorMessage}</div>}
          <input
            type="text"
            className="border p-2 w-full mb-2"
            placeholder="Name"
            value={personName}
            onChange={(e) => setPersonName(e.target.value)}
          />
          <input
            type="email"
            className="border p-2 w-full mb-2"
            placeholder="Email"
            value={personEmail}
            onChange={(e) => setPersonEmail(e.target.value)}
          />
          <button onClick={handleAddPerson} className="bg-blue-500 text-white p-3 rounded w-full flex items-center justify-center">
            <i className="fa fa-plus text-2xl mr-2"></i>
            Add Person
          </button>
        </div>
      </div>
    )}

<div className="bg-white p-6 shadow-lg rounded-lg w-full max-w-4xl mb-8"> {/* Changed max-w-xl to max-w-4xl */}
    <div className="flex justify-between items-center mb-4">
      <h2 className="text-xl font-bold">Task List</h2>
      <div className="flex items-center">
        {tasks.length > 0 && (
          <button onClick={exportTasksToCSV} className="text-green-500 text-xl mr-2">
            <i className="fa fa-file-excel-o"></i>
          </button>
        )}
        <button 
          onClick={() => setShowTaskModal(true)} 
          className="border border-blue-500 text-blue-500 rounded transition duration-300 
                     bg-white hover:bg-blue-500 hover:text-white w-10 h-10 flex items-center justify-center"
        >
          <i className="fa fa-plus"></i>
        </button>
      </div>
    </div>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search tasks..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border p-2 w-full"
        />
      </div>
      <div className="flex mb-4">
        <select
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}
          className="border p-2 mr-2"
        >
          <option value="All">All Statuses</option>
          <option value="Pending">Pending</option>
          <option value="In Progress">In Progress</option>
          <option value="Completed">Completed</option>
        </select>
        <select
          value={filterPriority}
          onChange={(e) => setFilterPriority(e.target.value)}
          className="border p-2"
        >
          <option value="All">All Priorities</option>
          <option value="High">High</option>
          <option value="Medium">Medium</option>
          <option value="Low">Low</option>
        </select>
      </div>
      <table className="w-full">
        <thead>
          <tr>
            <th className="border p-2">Task Name</th>
            <th className="border p-2">Due Date</th>
            <th className="border p-2">Assigned To</th>
            <th className="border p-2">Status</th>
            <th className="border p-2">Priority</th>
            <th className="border p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
        {filteredTasks.map((task) => (
  <tr key={task.id} className={task.status === "Completed" ? "bg-gray-300" : ""}>
    <td className="border p-2">{task.name}</td>
    <td className="border p-2">{task.dueDate ? task.dueDate.toLocaleDateString() : "N/A"}</td>
    <td className="border p-2">{task.assignedTo}</td>
    <td className="border p-2">
      <select 
        value={task.status} 
        onChange={(e) => handleChangeTaskStatus(task.id, e.target.value)}
        className="border p-1"
      >
        <option value="Pending">Pending</option>
        <option value="In Progress">In Progress</option>
        <option value="Completed">Completed</option>
      </select>
    </td>
    <td className="border p-2">{task.priority}</td>
    <td className="border p-2">
      <div className="flex justify-center space-x-2">
        <button 
          onClick={() => handleEditTask(task)} 
          className="text-blue-500 hover:text-blue-700"
          title="Edit Task"
        >
          <i className="fa fa-edit"></i>
        </button>
        <button 
          onClick={() => handleDeleteTask(task.id)} 
          className="text-red-500 hover:text-red-700"
          title="Delete Task"
        >
          <i className="fa fa-trash"></i>
        </button>
      </div>
    </td>
  </tr>
))}
        </tbody>
      </table>
    </div>

    <div className="bg-white p-6 shadow-lg rounded-lg w-full max-w-4xl mb-8"> {/* Changed max-w-xl to max-w-4xl */}
    <div className="flex justify-between items-center mb-2">
      <h2 className="text-xl font-bold">Assigned People</h2>
      <div className="flex items-center">

        <button 
          onClick={() => setShowPersonModal(true)} 
          className="border border-blue-500 text-blue-500 rounded transition duration-300 
                     bg-white hover:bg-blue-500 hover:text-white w-10 h-10 flex items-center justify-center"
        >
          <i className="fa fa-plus"></i>
        </button>
      </div>
    </div>
      <table className="w-full">
        <thead>
          <tr>
            <th className="border p-2">Name</th>
            <th className="border p-2">Email</th>
            <th className="border p-2">Action</th>
          </tr>
        </thead>
        <tbody>
          {assignedPeople.map((person) => (
            <tr key={person.id}>
              <td className="border p-2">{person.name}</td>
              <td className="border p-2">{person.email}</td>
              <td className="border p-2">
                <button onClick={() => handleRemovePerson(person.id)} className="text-red-500">
                  <i className="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    <footer className="w-full text-center p-4 bg-gray-200">
      <p>© Carlos Guerrero v1.2</p>
    </footer>
  </div>
);
};

export default App;